import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function VisibleModal({ title, data, onChange, onClose, onSave, children }) {
  return (
    <div
      className="modal show"
      onClick={() => onClose()}
      style={{ display: "block", position: "central", background: "#00000080" }}
    >
      <Modal.Dialog
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {children || (
            <input
              onChange={(e) => onChange(e.target.value)}
              value={data.titulo}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="CloseButton"
            variant="outline-dark"
            onClick={() => onClose()}
          >
            Fechar
          </Button>
          {onSave && <Button variant="outline-dark" onClick={() => onSave()}>
            Salvar
          </Button>}
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

export default VisibleModal;
