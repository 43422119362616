import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Prev } from "react-bootstrap/esm/PageItem";
import { useLocalStorage } from "./useLocalStorage";
import Form from "react-bootstrap/Form";
import VisibleModal from "./Modal";
import { CloseButton, Row } from "react-bootstrap";
import {
  BsFillTrashFill,
  BsFillPencilFill,
  BsWhatsapp,
  BsPlusCircle,
} from "react-icons/bs";
import { AiOutlineClear, AiOutlineQrcode } from 'react-icons/ai'
import Illustration from "./illustration.svg";
import QRCode from "react-qr-code";

function ListItemDetails({
  clearAllCbx,
  goBack,
  onRemove,
  onTitleChange,
  onRemoveItem,
  onNewItem,
  onEditingItem,
  onItemChange,
  ...data
}) {
  const [titleForUpdate, setTitleForUpdate] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [newItemData, setNewItemData] = useState(null);
  const [qrcodeVisible, setQrcodeVisible] = useState(false);

  const switchDone = (idx) => {
    const itemData = data.itens[idx];
    onItemChange(idx, {
      ...itemData,
      done: !itemData.done,
    });
  };

  const shareUrl = useMemo(() => {
    const url = new URL(window.location);
    url.searchParams.set("listData", encodeURI(JSON.stringify(data)));
    return url.href;
  }, [data]);

  console.log(shareUrl);

  return (
    <>
      <Button
        onClick={() => window.confirm(`Deseja remover a lista?`) && onRemove()}
        className="remove-list-button"
        variant="danger"
      >
        Apagar a lista
      </Button>

      {qrcodeVisible && (
        <VisibleModal title="QRCODE" onClose={() => setQrcodeVisible(false)}>
          <div
            style={{
              height: "100%",
              margin: "0 auto",
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "15rem", maxWidth: "100%", width: "100%" }}
              value={shareUrl}
              viewBox={`0 0 256 256`}
            />
          </div>
        </VisibleModal>
      )}

      {newItemData && (
        <VisibleModal
          title="Criar Novo Item"
          data={newItemData}
          onChange={(e) => setNewItemData((prev) => ({ ...prev, titulo: e }))}
          onClose={() => setNewItemData(null)}
          onSave={() => {
            onNewItem(newItemData);
            setNewItemData(null);
          }}
        />
      )}

      {isUpdating && (
        <VisibleModal
          title="Editar titulo"
          data={{ titulo: titleForUpdate }}
          onChange={(e) => setTitleForUpdate(e)}
          onClose={() => setIsUpdating(false)}
          onSave={() => {
            setTitleForUpdate(null);
            setIsUpdating(false);
            onTitleChange(titleForUpdate);
          }}
        />
      )}
      <br />
      <h1
        className="text-white m-0"
        onClick={() => {
          setIsUpdating(true);
          setTitleForUpdate(data.titulo);
        }}
      >
        {data.titulo}
        <BsFillPencilFill size={16} style={{ margin: "0 .3rem" }} />
      </h1>
      <hr />

      <div />

      <div />

      <div className="item-container">
        {data.itens.map((item, idx) => (
          <div className="item-row" onClick={() => switchDone(idx)}>
            <Form.Check
              type={"checkbox"}
              name={idx}
              checked={item.done}
              label={
                <p
                  className="text-white"
                  style={{
                    textDecoration: item.done ? "line-through" : "none",
                    margin: 0,
                  }}
                >
                  {item.titulo}
                </p>
              }
            />
            <div>
              <BsFillTrashFill
                className="Latadelixo"
                onClick={(e) => {
                  e.stopPropagation();
                  window.confirm(`Deseja remover o item ${item.titulo}?`) &&
                    onRemoveItem(idx);
                }}
                variant="outline-light"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="bottom-bar">
        <div
        className="icon-row"
        >
          {" "}
          <Button
            variant="outline-light"
            value={data.item}
            onClick={() => setNewItemData({ titulo: "Novo Item", done: false })}
            className="bottom-button"
          >
            <BsPlusCircle></BsPlusCircle>
          </Button>
          <Button
            variant="outline-light"
            value={data.item}
            onClick={() => {
              window.open(`https://api.whatsapp.com/send?text=${shareUrl}`);
            }}
            className="bottom-button"
          >
            <BsWhatsapp></BsWhatsapp>
          </Button>
          <Button
            className="bottom-button"
            variant="outline-light"
            onClick={() => setQrcodeVisible(true)}
          >
            <AiOutlineQrcode />
          </Button>
          <Button
            className="bottom-button"
            variant="outline-light"
            onClick={clearAllCbx}
          >
            <AiOutlineClear />
          </Button>
        </div>

        <Button
          className="bottom-button"
          variant="outline-light"
          onClick={goBack}
        >
          Voltar
        </Button>
      </div>
    </>
  );
}

function List() {
  const [data, setData] = useLocalStorage("list-data", []);
  const [newListData, setNewListData] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const url = new URL(window.location);
    const listData = url.searchParams.get("listData");
    if (listData) {
      setData((prev) => {
        const list = JSON.parse(decodeURI(listData));
        if (!prev.some(({ titulo }) => titulo == list.titulo)) {
          prev.push(list);
        } else if (
          window.confirm(
            `Uma lista com nome ${list.titulo} já existe, deseja substituir?`
          )
        ) {
          const existingList = prev.findIndex(
            ({ titulo }) => titulo == list.titulo
          );
          prev[existingList] = list;
        }
        return [...prev];
      });
      window.location.href = "/";
    }
  }, []);

  if (selectedId !== null) {
    const listItem = data[selectedId];
    return (
      <ListItemDetails
        {...listItem}
        setNewListData={setNewListData}
        goBack={() => setSelectedId(null)}
        onTitleChange={(titulo) => {
          setData((prev) => {
            prev[selectedId].titulo = titulo;
            return [...prev];
          });
        }}
        onRemove={() => {
          setData((prev) => {
            delete prev[selectedId];
            return [...prev.filter(Boolean)];
          });
          setSelectedId(null);
        }}
        onRemoveItem={(idx) => {
          setData((prev) => {
            delete prev[selectedId].itens[idx];
            prev[selectedId].itens = prev[selectedId].itens.filter(Boolean);
            return [...prev];
          });
        }}
        clearAllCbx={(idx) => {
          setData((prev) => {
            prev[selectedId].itens = prev[selectedId].itens.map((i) => ({
              ...i,
              done: false,
            }));

            return [...prev];
          });
        }}
        onNewItem={(newItem) => {
          setData((prev) => {
            prev[selectedId].itens.push(newItem);
            return [...prev];
          });
        }}
        onItemChange={(idx, newItem) => {
          setData((prev) => {
            prev[selectedId].itens[idx] = newItem;
            return [...prev];
          });
        }}
      />
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
      }}
    >
      {newListData && (
        <VisibleModal
          data={newListData}
          onChange={(titulo) => setNewListData((prev) => ({ ...prev, titulo }))}
          onClose={() => setNewListData(null)}
          onSave={() => {
            setData((prev) => [...prev, newListData]);
            setNewListData(null);
          }}
        />
      )}
      <img
        src={Illustration}
        width="60%"
        style={{
          margin: "2rem auto",
        }}
      />
      <h1 className="text-white">Minhas Tarefas</h1>
      <hr />
      <div style={{ overflow: "auto", width: "100%" }}>
        {data.map((item, idx) => (
          <Button
            variant="outline-light"
            onClick={() => setSelectedId(idx)}
            className="list-button"
          >
            {item.titulo}
          </Button>
        ))}
      </div>
      <div className="bottom-bar">
        <Button
          variant="primary"
          onClick={() => setNewListData({ titulo: "Nova lista", itens: [] })}
          className="bottom-button"
        >
          Criar nova lista
        </Button>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <List />
      </div>
    </div>
  );
}

export default App;
